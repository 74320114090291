
import { api_addSchedule, api_autoHideSchedule, api_deleteSchedule, api_editSchedule, api_listSchedule, api_publishSchedule } from '@/api/children/schedule';
import { DateTimePicker } from '@/component/prompt';
import { User } from '@/user';
import {
    EditOutlined
} from '@ant-design/icons';
import { Button, Drawer, Space, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { PureComponent } from 'react';
import { Seats } from '../Seat';

type SchedulesProps = {
    shortName?: string
    projectId: number
    activityId: number
    user: User
}
type SchedulesState = {
    items?: DataType[]
    manageingScheduleId?: number
    manageingShortName?: string
}
interface DataType {
    scheduleId: number;
    key: React.Key;
    createTime: Date;
    published: boolean;
    autoHide: boolean;
    startTime: Date
    endTime: Date
    regEndTime: Date
    SeatCount: number
    SeatLeftCount: number
}


export class Schedules extends PureComponent<SchedulesProps, SchedulesState> {
    constructor(props: SchedulesProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <div>
            <Button type="primary" style={{ margin: 10 }} onClick={this.addSchedule.bind(this)}>{"新建场次"}</Button>
            <Table
                columns={this.columns}
                dataSource={this.state.items}
                scroll={{
                    x: 1400
                }}
            />

            <Drawer
                title={`座位管理(${this.state.manageingShortName})`}
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={() => { this.setState({ manageingScheduleId: undefined }); this.fetchData() }}
                open={typeof this.state.manageingScheduleId === "number"}>
                {this.state.manageingScheduleId ? <Seats 
                projectId={this.props.projectId} 
                scheduleId={this.state.manageingScheduleId} 
                user={this.props.user} /> : ""}
            </Drawer>


        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    columns: ColumnsType<DataType> = [
        {
            title: <Tooltip title={"场次ID"}>{'ID'}</Tooltip>,
            dataIndex: 'key',
            key: 'key',
            width: 50,
        },
        {
            title: '座位(剩/总)',
            dataIndex: 'SeatCount',
            key: 'SeatCount',
            render: (t, item) => <Tooltip title="点击管理活动场数">
                <Button type="dashed" onClick={() => this.manage(item.scheduleId, item.startTime.toLocaleString())}>{`${item.SeatLeftCount} / ${item.SeatCount}`}</Button>
            </Tooltip>,
            width: 110,
        }, {
            title: '参考状态',
            dataIndex: 'SeatCount',
            key: 'states',
            render: (t, item) => <Tooltip title="草稿 → 报名 → 满额 → 截止 → 开始 → 结束">
                {(() => {
                    if (!item.published) {
                        return <Tag>{"草稿"}</Tag>
                    } else if (new Date(item.endTime).valueOf() < new Date().valueOf()) {
                        return <Tag>{"结束"}</Tag>
                    } else if (new Date(item.startTime).valueOf() < new Date().valueOf()) {
                        return <Tag color="purple">{"开始"}</Tag>
                    } else if (new Date(item.regEndTime).valueOf() < new Date().valueOf()) {
                        return <Tag color="geekblue">{"截止"}</Tag>
                    } else if (item.SeatLeftCount === 0) {
                        return <Tag color="cyan">{"满额"}</Tag>
                    } else {
                        return <Tag color="green">{"报名"}</Tag>
                    }
                    {/* <Tag color="green">{"是"}</Tag> : <Tag color="red">{"否"}</Tag>{, */ }
                })()}
            </Tooltip>,


            width: 80,
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            // render: (t, item) => new Date(item.startTime).toLocaleString(),
            render: (t, item) => <span style={{ cursor: "pointer" }} onClick={() => this.changeTime("startTime", item)}>
                <Tooltip title="点击修改">{new Date(item.startTime).toLocaleString()}<EditOutlined style={{ marginLeft: 5, color: "#40a9ff" }} /></Tooltip>
            </span>,
            width: 155,
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (t, item) => <span style={{ cursor: "pointer" }} onClick={() => this.changeTime("endTime", item)}>
                <Tooltip title="点击修改">{new Date(item.endTime).toLocaleString()}<EditOutlined style={{ marginLeft: 5, color: "#40a9ff" }} /></Tooltip>
            </span>,
            width: 155,
        },
        {
            title: <Tooltip title={"截止接受登记时间"}>{'登记截止时间'}</Tooltip>,
            dataIndex: 'regEndTime',
            key: 'regEndTime',
            render: (t, item) => <span style={{ cursor: "pointer" }} onClick={() => this.changeTime("regEndTime", item)}>
                <Tooltip title="点击修改">{new Date(item.regEndTime).toLocaleString()}<EditOutlined style={{ marginLeft: 5, color: "#40a9ff" }} /></Tooltip>
            </span>,
            width: 155,
        },
        {
            title: '已上架',
            dataIndex: 'published',
            key: 'published',
            render: (t, item) => item.published ? <Tag color="green">{"是"}</Tag> : <Tag color="red">{"否"}</Tag>,
            width: 80,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (t, item) => item.createTime.toLocaleString(),
            width: 145,
        },
        {
            title: <Tooltip title={"超过活动结束时间后隐藏该日期"}>{'过期隐藏'}</Tooltip>,
            dataIndex: 'createTime',
            key: 'createTime',
            render: (t, item) => item.autoHide ? <Tag color="green">{"是"}</Tag> : <Tag color="red">{"否"}</Tag>,
            width: 80,
        },
        {
            title: '控制',
            key: 'action',
            fixed: "right",
            width: 220,
            render: (_, item) => (
                <Space size="middle">
                    {/* <a onClick={() => this.rename(item.scheduleId)}>{"重命名"}</a> */}
                    <a onClick={() => this.publish(item.scheduleId, item.published)}>{item.published ? "下架" : "上架"}</a>
                    <a onClick={() => this.delete(item.scheduleId)}>{"删除"}</a>
                    <a onClick={() => this.autoHide(item.scheduleId, item.autoHide)}>{"切换自动隐藏"}</a>
                </Space>
            ),
        },
    ];
    async fetchData() {
        const data = await api_listSchedule(this.props.activityId)
        const items: DataType[] = Array.from(data.data, (item) => {
            return {
                scheduleId: item.id,
                key: String(item.id),
                startTime: new Date(item.startTime),
                endTime: new Date(item.endTime),
                regEndTime: new Date(item.regEndTime),
                createTime: new Date(item.createTime),
                published: item.published,
                autoHide: item.autoHide,

                SeatCount: item.Seat.length,
                SeatLeftCount: typeof item.orderableCount !== "number" ? 99 : item.orderableCount,
            }
        })
        this.setState({ items })
    }
    async addSchedule() {
        await api_addSchedule(this.props.activityId, {
            startTime:  new Date(new Date().valueOf() + 1000 * 3600 * 24),
            endTime: new Date(new Date().valueOf() + 1000 * 3600 * 48),
            regEndTime: new Date(new Date().valueOf() + 1000 * 3600 * 8),
        })
        this.fetchData()
    }

    async changeTime(type: "startTime" | "endTime" | "regEndTime", item: DataType) {
        let scheduleId = item.scheduleId
        let describe = "未知文案"
        let time = new Date()
        switch (type) {
            case "startTime":
                describe = `修改ID为 ${item.scheduleId} 的 开始时间`
                time = item.startTime
                break;
            case "endTime":
                describe = `修改ID为 ${item.scheduleId} 的 结束时间`
                time = item.endTime
                break;
            case "regEndTime":
                describe = `修改ID为 ${item.scheduleId} 的 登记截止时间`
                time = item.regEndTime
                break;
            default:
                break;
        }

        const result = await DateTimePicker(describe, time, `该场次的所有记录中的时间都会随之改变`)
        if (result) {
            await api_editSchedule(scheduleId, { [type]: result, })
            this.fetchData()

        }

    }











    async publish(scheduleId: number, currentPublishState: boolean) {
        await api_publishSchedule(scheduleId, !currentPublishState)
        this.fetchData()
    }
    async autoHide(scheduleId: number, autoHide: boolean) {
        await api_autoHideSchedule(scheduleId, !autoHide)
        this.fetchData()
    }
    async delete(scheduleId: number,) {
        if (confirm(`删除id为${scheduleId}的场次吗`)) {
            await api_deleteSchedule(scheduleId)
            this.fetchData()
        }
    }
    async manage(scheduleId: number, name: string) {
        this.setState({
            manageingScheduleId: scheduleId,
            manageingShortName: name,
        })
    }


}

