type MailTemplate = {
    to: string
    title: string
    mailHTML: string
}
type MailOutput = {
    to: string
    title: string
    mailHTML: string
    variables: { value: string, key: string }[]
}
type MailVariable = {
    EMAIL: string
    NAME: string
    ACTIVITY: string
} & {
    [str: string]: string
}
export const transformMailTemplate = (template: MailTemplate, variables: MailVariable): MailOutput => {
    let to = template.to
    let title = template.title
    let mailHTML = template.mailHTML
    const outVariables: MailOutput["variables"] = []
    for (let variableKey in variables) {
        const value = variables[variableKey]
        outVariables.push ({value,key:variableKey})
        to = to.replaceAll(`[[${variableKey}]]`, value)
        title = title.replaceAll(`[[${variableKey}]]`, value)
        mailHTML = mailHTML.replaceAll(`[[${variableKey}]]`, value)
    }
    return {
        to,
        title,
        mailHTML,
        variables: outVariables,

    }
}