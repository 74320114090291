

import { User } from '@/user';
import { Tabs } from 'antd';
import { PureComponent } from 'react';
import { EmailManager } from './mail';

type AfterSubmitProps = {
    // shortName?: string
    projectId: number
    user: User
}
type SchedulesState = {
   
}


export class AfterSubmit extends PureComponent<AfterSubmitProps, SchedulesState> {
    constructor(props: AfterSubmitProps) {
        super(props)
        this.state = {
        }
    }
    render() {
        return <div>
            <div>{"报名成功之后的触发器"}</div>
            <Tabs defaultActiveKey="1" >
                <Tabs.TabPane tab={<div style={{ padding: 10 }}>{"邮件通知"}</div>} key="1">
                   <EmailManager  projectId={this.props.projectId} user={this.props.user} />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Tab 2" key="2">
                    Content of Tab Pane 2
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tab 3" key="3">
                    Content of Tab Pane 3
                </Tabs.TabPane> */}
            </Tabs>
            {/* <Button type="primary" style={{ margin: 10 }} onClick={this.addSchedule.bind(this)}>{"新建场次"}</Button>
            <Table
                columns={this.columns}
                dataSource={this.state.items}
                scroll={{
                    x: 1400
                }}
            /> */}

            {/* <Drawer
                title={`座位管理(${this.state.manageingShortName})`}
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={() => { this.setState({ manageingScheduleId: undefined }); this.fetchData() }}
                open={typeof this.state.manageingScheduleId === "number"}>
                {this.state.manageingScheduleId ? <Seats scheduleId={this.state.manageingScheduleId} user={this.props.user} /> : ""}
            </Drawer> */}


        </div>

    }
  
 

}

