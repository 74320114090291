import { Button, Card, Input, message, Skeleton, Space } from "antd";
import { ChangeEvent, PureComponent } from "react";
import { MAIL_TEMPLATE } from "./defaultMail";

import { api_mailAdd, api_mailDelete, api_mailEdit, api_mailEnabled, api_mailGet, api_mailTest } from "@/api/children/mail";
import Editor from "@monaco-editor/react";
import "./editor.less";
import { RenderTemplate } from "./renderTemplate";



type MailEditorProps = {
    mailTemplateId: number
    onClose: () => void
}

type State = {
    data?: DataType
}


interface DataType {
    id: number;
    title: string;
    template: string;
    addressee: string;
    url: string;
    token: string;
    enabled: boolean;
    createTime: string | Date;
    projectId: number;
}








export class MailEditor extends PureComponent<MailEditorProps, State> {
    constructor(props: MailEditorProps) {
        super(props)
        this.state = {
        }
    }
    render() {
        if (!this.state.data) {
            return <Skeleton active />
        }
        const data = this.state.data
        return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Card style={{ width: "100%", margin: 10 }}>
                <Space size="middle" style={{ display: 'flex' }}>
                    <Button type="primary" onClick={this.save.bind(this)}>{"保存"}</Button>
                    <Button  onClick={this.test.bind(this)}>{"发送测试邮件"}</Button>
                    {data.enabled ? <Button onClick={this.disable.bind(this)}>{"停用此模板"}</Button> :
                        <Button danger onClick={this.disable.bind(this)}>{"启用此模板"}</Button>}
                    <Button type="primary" danger onClick={this.delete.bind(this)}>{"删除模板"}</Button>
                </Space>
            </Card>

            <div style={{ display: "flex", padding: 10, height: "calc(100% - 82px)" }}>
                <div style={{ width: "100%", maxHeight: "calc(100% - 55px)" }}>
                    <Space direction="vertical" size="small" style={{ display: 'flex', padding: 0, marginBottom: 24 }}>
                        <Input addonBefore="发信服务器" value={data.url} onChange={(event) => this.inputUpdate(event, "url")} />
                        <Input addonBefore="秘钥" value={data.token} onChange={(event) => this.inputUpdate(event, "token")} />
                        <Input addonBefore="收件人" value={data.addressee} onChange={(event) => this.inputUpdate(event, "addressee")} />
                        <Input addonBefore="主题名称" value={data.title} onChange={(event) => this.inputUpdate(event, "title")} />
                    </Space>
                    <Editor
                        height={"calc(100% - 120px)"}
                        defaultLanguage="html"
                        defaultValue={data.template}
                        onChange={this.onEditorChange.bind(this)} />
                </div>
                <RenderTemplate
                    to={data.addressee}
                    title={data.title}
                    templateHTML={data.template} />
            </div>
        </div>
    }
    componentDidMount(): void {
        this.fetchData()
    }
    inputUpdate(event: ChangeEvent<HTMLInputElement>, id: keyof DataType) {
        const data = this.state.data
        if (!data) { return }
        //@ts-ignore
        data[id] = event.target.value;
        this.setState({ data })
        this.forceUpdate()
    }
    async fetchData() {
        const data = await api_mailGet(this.props.mailTemplateId)
        this.setState({ data: data.data })
    }
    private onEditorChange(value: string | undefined,) {
        const data = this.state.data
        if (!value || !data) { return }
        data.template = value
        this.setState({ data })
        this.forceUpdate()
    }
    async save() {
        const data = this.state.data
        if (!data) { return }
        const response = await api_mailEdit(this.props.mailTemplateId, {
            ...data
        })
        if (response.data) {
            message.success('完成');
        }
        this.fetchData()

    }
    async disable() {
        const data = this.state.data
        if (!data) { return }

        const enabled = !this.state.data?.enabled
        const response = await api_mailEnabled(this.props.mailTemplateId, enabled)
        if (response.data) {
            message.success('完成');
        }
        this.fetchData()
    }
    async delete() {
        const response = await api_mailDelete(this.props.mailTemplateId)
        if (response.data) {
            message.success('完成');
        }
        if (this.props.onClose) {
            this.props.onClose()
        }
    }
    async test() {
        const response = await api_mailTest(this.props.mailTemplateId,`John.xie@4positives.com`)
        if (response.data) {
            message.success('完成');
        }
     
    }
}

