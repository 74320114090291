import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"

export type MailAPIs = APIExample<{
    "/mail/list": {
        method: "POST"
        toServer: {
            ticket: string
            projectId: number
        },
        toClient: {
            data: {
                id: number
                title: string
                template: string
                addressee: string
                url: string
                token: string
                enabled: boolean
                createTime: Date | string
                projectId: number
            }[]
        }
    },
    "/mail/get": {
        method: "POST"
        toServer: {
            ticket: string
            mailTemplateId: number
        },
        toClient: {
            data: {
                id: number
                title: string
                template: string
                addressee: string
                url: string
                token: string
                enabled: boolean
                createTime: Date | string
                projectId: number
            }
        }
    },
    "/mail/add": {
        method: "POST"
        toServer: {
            ticket: string
            projectId: number
            title: string
            template: string
            addressee: string
            token: string
            url: string
        },
        toClient: {}
    },
    "/mail/edit": {
        method: "POST"
        toServer: {
            ticket: string
            mailTemplateId: number
            title: string
            template: string
            addressee: string
            token: string
            url: string
        },
        toClient: {
            data: {}
        }
    },
    "/mail/test": {
        method: "POST"
        toServer: {
            ticket: string
            mailTemplateId: number
            addressee: string
        },
        toClient: {
            data: {}
        }
    },
    "/mail/enabled": {
        method: "POST"
        toServer: {
            ticket: string
            mailTemplateId: number
            enabled: boolean
        },
        toClient: {
            data: {}
        }
    },
    "/mail/delete": {
        method: "POST"
        toServer: {
            ticket: string
            mailTemplateId: number
        },
        toClient: {
            data: {}
        }
    },
    "/mail/debug": {
        method: "POST"
        toServer: {
            ticket: string
        },
        toClient: {

        }
    },
}>

export const api_mailList = async (projectId: number) => {
    return await apiRequest("/mail/list", {
        ticket: getTicket(),
        projectId,
    })
}

export const api_mailAdd = async (projectId: number, data: {
    title: string
    template: string
    addressee: string
    token: string
    url: URL
}) => {
    return await apiRequest("/mail/add", {
        ticket: getTicket(),
        projectId,
        title: data.title,
        template: data.template,
        addressee: data.addressee,
        token: data.token,
        url: data.url.href,
    })
}

export const api_mailGet = async (mailTemplateId: number) => {
    return await apiRequest("/mail/get", {
        ticket: getTicket(),
        mailTemplateId,
    })
}
export const api_mailEdit = async (mailTemplateId: number, data: {
    title: string
    template: string
    addressee: string
    token: string
    url: string
}) => {
    return await apiRequest("/mail/edit", {
        ticket: getTicket(),
        mailTemplateId,
        title: data.title,
        template: data.template,
        addressee: data.addressee,
        token: data.token,
        url: data.url,
    })
}

export const api_mailTest = async (mailTemplateId: number, addressee: string) => {
    return await apiRequest("/mail/test", {
        ticket: getTicket(),
        mailTemplateId,
        addressee,
    })
}

export const api_mailEnabled = async (mailTemplateId: number, enabled: boolean) => {
    return await apiRequest("/mail/enabled", {
        ticket: getTicket(),
        mailTemplateId,
        enabled,
    })
}
export const api_mailDelete = async (mailTemplateId: number) => {
    return await apiRequest("/mail/delete", {
        ticket: getTicket(),
        mailTemplateId,
    })
}
export const api_mailDebug = async () => {
    return await apiRequest("/mail/debug", {
        ticket: getTicket(),
    })
}