
import { api_addActivity, api_deleteActivity, api_listActivity, api_publishActivity, api_renameActivity } from '@/api/children/activity';
import { User } from '@/user';
import { Button, Drawer, Space, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import React, { PureComponent } from 'react';
import { AfterSubmit } from '../AfterSubmit';
import { Schedules } from '../Schedule';



type ActivitysProps = {
    shortName?: string
    projectId: number
    user: User
}
type ActivitysState = {
    items?: DataType[]
    manageingActivityId?: number
    manageingActivityshortName?: string
    manageHandle?: boolean
}
interface DataType {
    activityId: number;
    key: React.Key;
    name: string;
    createTime: Date;
    published: boolean;
    ScheduleCount: number;
}


export class Activitys extends PureComponent<ActivitysProps, ActivitysState> {
    constructor(props: ActivitysProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <div>
            <Button type="primary" style={{ margin: 10, }} onClick={this.addItem.bind(this)}>{"新建活动"}</Button>
            <Button style={{ margin: 10, }} onClick={this.manageHandle.bind(this)}>{"管理Handle"}</Button>
            <Table columns={this.columns} dataSource={this.state.items} />
            <Drawer
                title={`管理Handle(${this.props.shortName})`}
                placement="right"
                closable
                destroyOnClose
                width={"95%"}
                onClose={this.onHandleClose.bind(this)}
                open={this.state.manageHandle}>
                {this.state.manageHandle ? <AfterSubmit projectId={this.props.projectId} user={this.props.user} /> : ""}
            </Drawer>
            <Drawer
                title={`场次管理(${this.state.manageingActivityshortName})`}
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={this.onManageClose.bind(this)}
                open={typeof this.state.manageingActivityId === "number"}>
                {this.state.manageingActivityId ? <Schedules
                 projectId={this.props.projectId} 
                 shortName={this.state.manageingActivityshortName} 
                 activityId={this.state.manageingActivityId} 
                 user={this.props.user} /> : ""}
            </Drawer>
        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    columns: ColumnsType<DataType> = [
        {
            title: '活动ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: '活动名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '上架',
            dataIndex: 'published',
            key: 'published',
            render: (t, item) => item.published ? <Tag color="green">{"是"}</Tag> : <Tag color="red">{"否"}</Tag>,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (t, item) => item.createTime.toLocaleString(),
        },
        {
            title: '场数',
            dataIndex: 'ScheduleCount',
            key: 'ScheduleCount',
            render: (t, item) => <Tooltip title="点击管理活动场数">
                <Button type="dashed" onClick={() => this.manage(item.activityId, item.name)}>{`${item.ScheduleCount} 场`}</Button>
            </Tooltip>
        },
        {
            title: '控制',
            key: 'action',
            fixed: 'right',
            width: 250,
            render: (_, item) => (
                <Space size="middle">
                    <a onClick={() => this.rename(item.activityId)}>{"重命名"}</a>
                    <a onClick={() => this.publish(item.activityId, item.published)}>{item.published ? "下架" : "上架"}</a>
                    <a onClick={() => this.delete(item.activityId)}>{"删除"}</a>
                </Space>
            ),
        },
    ];
    async fetchData() {
        const data = await api_listActivity(this.props.projectId)
        const items: DataType[] = Array.from(data.data, (item) => {
            return {
                activityId: item.id,
                key: String(item.id),
                name: item.name,
                createTime: new Date(item.createTime),
                published: item.published,
                ScheduleCount: item.Schedule.length
            }
        })
        this.setState({
            items
        })
    }
    async addItem() {
        await api_addActivity(this.props.projectId, `未命名活动-${new Date().valueOf()}`)
        this.fetchData()
    }
    async manageHandle() {
        this.setState({ manageHandle: true })
    }
    async rename(activityId: number) {
        const newName = prompt("新的活动名称");
        if (newName) {
            await api_renameActivity(activityId, newName)
        }
        this.fetchData()
    }
    async publish(activityId: number, currentPublishState: boolean) {
        await api_publishActivity(activityId, !currentPublishState)
        this.fetchData()
    }
    async delete(activityId: number,) {
        if (confirm(`删除id为${activityId}的活动吗`)) {
            await api_deleteActivity(activityId)
            this.fetchData()
        }

    }
    async manage(activityId: number, name: string) {
        this.setState({
            manageingActivityId: activityId,
            manageingActivityshortName: name,
        })
    }
    async onManageClose() {
        this.fetchData()
        this.setState({ manageingActivityId: undefined })
    }
    async onHandleClose() {
        this.fetchData()
        this.setState({ manageHandle: undefined })
    }

}

