
import { api_addPubOrder, api_setDefaultSchedule } from '@/api/children/order';
import { api_addSeat, api_deleteSeat, api_listSeat } from '@/api/children/seat';
import { User } from '@/user';
import { Button, Drawer, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { PureComponent } from 'react';
import { Orders } from '../Order';

type SeatsProps = {
    projectId: number
    scheduleId: number
    user: User
}
type SeatsState = {
    items?: DataType[]
    manageingSeatId?: number
}
interface DataType {
    seatId: number;
    key: React.Key;
    createTime: Date;
    ordered: boolean
}


export class Seats extends PureComponent<SeatsProps, SeatsState> {
    constructor(props: SeatsProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <div>
            <div style={{ display: this.props.user.userLevel === "viewer" ? "none" : undefined }}>
                <Button type="primary" style={{ margin: 10 }} onClick={this.addSeat.bind(this)}>{"新建座位"}</Button>
                <Button style={{ margin: 10 }} onClick={this.addPubOrder.bind(this)}>{"模拟占位"}</Button>
                <Button style={{ margin: 10 }} onClick={this.setDefaultSchedule.bind(this)}>{"设置为未指定目标时的默认目标"}</Button>
                <Button style={{ margin: 10 }} onClick={this.addDefaultPubOrder.bind(this)}>{"模拟未指定目标的报名"}</Button>
                <div>
                    {"开启“设置为未指定目标时的默认目标”之后，允许前台在报名时不提供具体的信息，统一计入指定的场次里，并且不限次数。此时记录的预定会被标记为已取消"}
                </div>
            </div>
            <Table columns={this.columns} dataSource={this.state.items} />

            <Drawer
                title="预定管理"
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={() => { this.setState({ manageingSeatId: undefined }); this.fetchData() }}
                open={typeof this.state.manageingSeatId === "number"}>
                {this.state.manageingSeatId ? <Orders seatId={this.state.manageingSeatId} user={this.props.user} /> : ""}
            </Drawer>


        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    columns: ColumnsType<DataType> = [
        {
            title: '座位ID',
            dataIndex: 'key',
            key: 'key',
        },

        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (t, item) => item.createTime.toLocaleString(),
        },
        {
            title: '已被预订',
            dataIndex: 'ordered',
            key: 'ordered',
            render: (t, item) => item.ordered ? <Tag color="red">{"已被预订"}</Tag> : <Tag color="green">{"空闲"}</Tag>
        },
        {
            title: '控制',
            key: 'action',
            render: (_, item) => (
                <Space size="middle">
                    <a onClick={() => this.manage(item.seatId)}>{"管理"}</a>
                    {this.props.user.userLevel === "viewer" ? "" : <a onClick={() => this.delete(item.seatId)}>{"删除"}</a>}
                </Space>
            ),
        },
    ];
    async fetchData() {
        const data = await api_listSeat(this.props.scheduleId)
        const items: DataType[] = Array.from(data.data, (item) => {
            return {
                seatId: item.id,
                key: String(item.id),
                createTime: new Date(item.createTime),
                ordered: item.Order.length === 1,
            }
        })
        this.setState({ items })
    }
    async addSeat() {
        await api_addSeat(this.props.scheduleId)
        this.fetchData()
    }
    async addPubOrder() {
        await api_addPubOrder(this.props.projectId, this.props.scheduleId)
        this.fetchData()
    }
    async setDefaultSchedule() {
        if (confirm(`要将id为${this.props.scheduleId}的场次设为未指定场次的报名的默认场次吗？当报名未指定场次时，填充往指派的目标场次的报名默认被取消，所以可以无限制提交，也不受开始和结束时间的限制。`)) {
            await api_setDefaultSchedule(this.props.scheduleId)
            this.fetchData()
        }
    }
    async addDefaultPubOrder() {
        await api_addPubOrder(this.props.projectId)
        this.fetchData()
    }
    async delete(seatId: number,) {
        if (confirm(`删除id为${seatId}的场次吗`)) {
            await api_deleteSeat(seatId)
            this.fetchData()
        }
    }
    async manage(seatId: number,) {
        this.setState({
            manageingSeatId: seatId
        })
    }


}

