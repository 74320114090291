import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"

export type ScheduleAPIs = APIExample<{
    "/schedule/pubList": {
        method: "POST"
        toServer: {
            ticket: string
            activityId: number
        },
        toClient: {
            data: {
                id: number
                startTime: Date | string
                endTime: Date | string
                regEndTime: Date | string
                hasAvailable?: boolean
            }[]
        }
    },
    "/schedule/list": {
        method: "POST"
        toServer: {
            ticket: string
            activityId: number
        },
        toClient: {
            data: {
                id: number
                published: boolean
                autoHide: boolean
                createTime: Date | string
                startTime: Date | string
                endTime: Date | string
                regEndTime: Date | string
                orderableCount?: number
                Seat: {}[]
            }[]
        }
    },
    "/schedule/add": {
        method: "POST"
        toServer: {
            ticket: string
            activityId: number
            startTime: Date | string
            endTime: Date | string
            regEndTime: Date | string
        },
        toClient: {}
    },
    "/schedule/publish": {
        method: "POST"
        toServer: {
            ticket: string
            scheduleId: number
            publish: boolean
        },
        toClient: {}
    },
    "/schedule/autoHide": {
        method: "POST"
        toServer: {
            ticket: string
            scheduleId: number
            autoHide: boolean
        },
        toClient: {}
    },
    "/schedule/edit": {
        method: "POST"
        toServer: {
            ticket: string
            scheduleId: number

            startTime?: Date | string
            endTime?: Date | string
            regEndTime?: Date | string
        },
        toClient: {}
    },
    "/schedule/delete": {
        method: "POST"
        toServer: {
            ticket: string
            scheduleId: number
        },
        toClient: {}
    },
}>


export const api_listSchedule = async (activityId: number) => {
    return await apiRequest("/schedule/list", {
        ticket: getTicket(),
        activityId,
    })
}
export const api_addSchedule = async (activityId: number, data: { startTime: Date, endTime: Date, regEndTime: Date, }) => {
    return await apiRequest("/schedule/add", {
        ticket: getTicket(),
        activityId,
        startTime: data.startTime,
        endTime: data.endTime,
        regEndTime: data.regEndTime,
    })
}

export const api_editSchedule = async (scheduleId: number, newData: { startTime?: Date, endTime?: Date, regEndTime?: Date, }) => {
    return await apiRequest("/schedule/edit", {
        ticket: getTicket(),
        scheduleId,
        startTime: newData.startTime,
        endTime: newData.endTime,
        regEndTime: newData.regEndTime,
    })
}

export const api_publishSchedule = async (scheduleId: number, publish: boolean) => {
    return await apiRequest("/schedule/publish", {
        ticket: getTicket(),
        scheduleId,
        publish,
    })
}
export const api_autoHideSchedule = async (scheduleId: number, autoHide: boolean) => {
    return await apiRequest("/schedule/autoHide", {
        ticket: getTicket(),
        scheduleId,
        autoHide,
    })
}
export const api_deleteSchedule = async (scheduleId: number,) => {
    return await apiRequest("/schedule/delete", {
        ticket: getTicket(),
        scheduleId,
    })
}