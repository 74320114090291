import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"

export type OrderAPIs = APIExample<{
    "/order/list": {
        method: "POST"
        toServer: {
            ticket: string
            seatId: number
        },
        toClient: {
            data: {
                agree: string
                id: number
                createTime: Date
                userGUID: string
                name: string
                phoneNumber: string
                email: string
                channel: string
                cancelled: boolean
            }[]
        }
    },
    "/order/pubAdd": {
        method: "POST"
        toServer: {
            /**传入任意值 */
            ticket: string
            
            userGUID: string
            name: string
            phoneNumber: string
            email: string

            channel?: string
        } & ({
            projectId: number
        } | {
            scheduleId: number
        }),
        toClient: {}
    },
    "/order/add": {
        method: "POST"
        toServer: {
            ticket: string
            seatId: number
        },
        toClient: {}
    },
    "/order/cancel": {
        method: "POST"
        toServer: {
            ticket: string
            orderId: number
            cancel: boolean
        },
        toClient: {}
    },
    // "/order/view": {
    //     method: "POST"
    //     toServer: {
    //         ticket: string

    //     },
    //     toClient: {}
    // },
}>


export const api_listOrder = async (seatId: number) => {
    return await apiRequest("/order/list", {
        ticket: getTicket(),
        seatId,
    })
}
export const api_addPubOrder = async (projectId: number, scheduleId?: number,) => {
    const random1 = Number(String(Math.random()).split(".").pop()).toString(36)
    const random2 = Number(Math.ceil(Math.random() * 100000000)).toString(36)
    const random3 = Math.ceil(Math.random() * 100000000)
    return await apiRequest("/order/pubAdd", {
        ticket: getTicket(),
        projectId,
        scheduleId,
        userGUID: "test",
        name: "pubAdd",
        phoneNumber: `${random3}`,
        email: `${random1}@${random2}.com`,
    })
}
export const api_setDefaultSchedule = async (defaultScheduleId: number,) => {
    return await apiRequest("/project/setDefaultScheduleId", {
        ticket: getTicket(),
        defaultScheduleId,
    })
}
export const api_addOrder = async (seatId: number,) => {
    return await apiRequest("/order/add", {
        ticket: getTicket(),
        seatId,
    })
}
export const api_cancelOrder = async (orderId: number, cancel: boolean) => {
    return await apiRequest("/order/cancel", {
        ticket: getTicket(),
        orderId,
        cancel
    })
}

