
import { api_addOrder, api_cancelOrder, api_listOrder } from '@/api/children/order';
import { User } from '@/user';
import { Button, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { PureComponent } from 'react';

type OrdersProps = {
    seatId: number
    user: User
}
type OrdersState = {
    items?: DataType[]
    manageingSeatId?: number
}
interface DataType {
    orderId: number;
    key: React.Key;
    name: string;
    createTime: Date;
    phoneNumber: string
    email: string
    cancelled: boolean
    userGUID: string
    channel: string
    agree: string
}



export class Orders extends PureComponent<OrdersProps, OrdersState> {
    constructor(props: OrdersProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <div>
            <Button style={{ margin: 10, display: this.props.user.userLevel === "viewer" ? "none" : undefined }} onClick={this.addOrder.bind(this)}>{"添加虚拟记录"}</Button>
            <Table columns={this.columns} dataSource={this.state.items} />
        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    columns: ColumnsType<DataType> = [
        {
            title: '座位ID',
            dataIndex: 'key',
            key: 'key',
        },

        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '电话',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (t, item) => item.createTime.toLocaleString(),
        },
        {
            title: '真实信息',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (t, item) => item.userGUID.startsWith("INT_") ? "否" : "是",
        },
        {
            title: '渠道',
            dataIndex: 'channel',
            key: 'channel',
            // render: (t, item) => item.channel ,
            render: (t, item) => item.channel  ||"(未知)",
        },
        {
          title: '是否同意',
          dataIndex: 'agree',
          key: 'agree',
          render: (t, item) => item.agree  ||"否",
        },
        {
            title: '已被预订',
            dataIndex: 'ordered',
            key: 'ordered',
            render: (t, item) => item.cancelled ? <Tag color="red">{"已取消"}</Tag> : <Tag color="green">{"当前预定"}</Tag>
        },
        {
            title: '控制',
            key: 'action',
            render: (_, item) => (
                <Space size="middle">
                    {/* <a onClick={() => this.manage(item.orderId)}>{"管理"}</a> */}
                    {item.cancelled ?
                        <a onClick={() => this.cancelOrder(item.orderId, false)}>{"恢复预定"}</a> :
                        <a onClick={() => this.cancelOrder(item.orderId, true)}>{"取消预定"}</a>}

                </Space>
            ),
        },
    ];
    async fetchData() {
        const data = await api_listOrder(this.props.seatId)
        const items: DataType[] = Array.from(data.data, (item) => {
            return {
                orderId: item.id,
                key: String(item.id),
                name: item.name,
                phoneNumber: item.phoneNumber,
                email: item.email,
                channel: item.channel,
                createTime: new Date(item.createTime),
                cancelled: item.cancelled,
                userGUID: item.userGUID,
                agree: item.agree
            }
        })
        this.setState({ items })
    }
    async addOrder() {
        await api_addOrder(this.props.seatId)
        this.fetchData()
    }
    async cancelOrder(orderId: number, cancel: boolean) {
        if (confirm(`${cancel ? "取消" : "恢复"}id为 ${orderId} 的预定吗`)) {
            await api_cancelOrder(orderId, cancel)
            this.fetchData()
        }
    }
}

