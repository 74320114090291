

import { api_addSchedule, api_autoHideSchedule, api_deleteSchedule, api_editSchedule, api_listSchedule, api_publishSchedule } from '@/api/children/schedule';
import { DateTimePicker } from '@/component/prompt';
import { User } from '@/user';
import {
    EditOutlined
} from '@ant-design/icons';
import { Button, Card, Drawer, Skeleton, Space, Table, Tabs, Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { PureComponent } from 'react';
import { MAIL_TEMPLATE } from './defaultMail';
import { encode, decode } from 'js-base64';
import { MailEditor } from './editor';
import { api_mailAdd, api_mailDebug, api_mailList } from '@/api/children/mail';
import { DotChartOutlined } from '@ant-design/icons';
import { faker } from '@faker-js/faker';
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

import "./index.less"
import { RenderTemplate } from './renderTemplate';


type AfterSubmitProps = {
    // shortName?: string
    projectId: number
    user: User
}
type SchedulesState = {
    items?: DataType[]
    // editingMailTemplateId?: number
    editingMailTemplateItem?: DataType
}
interface DataType {
    mailTemplateId: number
    projectId: number,
    server: URL;
    token: string;
    to: string;
    title: string;
    mailHTML: string;
    enabled: boolean;
}



export class EmailManager extends PureComponent<AfterSubmitProps, SchedulesState> {
    constructor(props: AfterSubmitProps) {
        super(props)
        this.state = {}
    }

    render() {
        return <div className='EmailManager' style={{ padding: 8 }}>
            <Card style={{ width: "100%", margin: 10 }}>
                <Space>
                    <Button onClick={this.newTemplate.bind(this)}>{"新建模板"}</Button>
                    <Button onClick={this.debug.bind(this)}>{"debug Request"}</Button>
                </Space>
            </Card>
            {this.state.items ? Array.from(this.state.items, item => {
                return <Card
                    title={item.title}
                    extra={item.enabled ? <Tag color={"cyan"}>{"已启用"}</Tag> : <Tag color={"red"}>{"已停用"}</Tag>}
                    hoverable
                    style={{ width: 600, display: "inline-block", margin: 10 }}
                    onClick={() => this.manageTemplate(item)}>
                    <RenderTemplate
                        to={item.to}
                        title={item.title}
                        templateHTML={item.mailHTML} />
                </Card>
            }) : <Skeleton active />}
            <Drawer
                title={`编辑邮件模板(${this.state.editingMailTemplateItem})`}
                placement="right"
                closable
                destroyOnClose
                width={"90%"}
                onClose={this.onEditingClose.bind(this)}
                open={typeof this.state.editingMailTemplateItem === "object"}>
                {this.state.editingMailTemplateItem ? <MailEditor
                    onClose={() => { this.setState({ editingMailTemplateItem: undefined }); this.fetchData() }}
                    mailTemplateId={this.state.editingMailTemplateItem.mailTemplateId}
                /> : ""}
            </Drawer>

        </div>

    }

    async componentDidMount() {
        this.fetchData()
    }

    async fetchData() {
        const data = await api_mailList(this.props.projectId)
        const items: DataType[] = Array.from(data.data, (item) => {
            let url = new URL(`https://1.1.1.1/`)

            try { url = new URL(item.url) } catch (error) { }

            return {
                mailTemplateId: item.id,
                key: String(item.id),
                projectId: item.projectId,
                enabled: item.enabled,
                server: url,
                token: item.token,
                to: item.addressee,
                title: item.title,
                mailHTML: item.template
            }
        })
        this.setState({ items })
    }
    async onEditingClose() {
        this.fetchData()
        this.setState({ editingMailTemplateItem: undefined })
    }
    manageTemplate(data: DataType) {
        this.setState({
            editingMailTemplateItem: data,
        })
    }

    async newTemplate() {
        const data = await api_mailAdd(this.props.projectId, {
            title: `发送给 [[NAME]] 的邮件标题`,
            template: MAIL_TEMPLATE,
            addressee: `[[EMAIL]]`,
            token: faker.datatype.uuid(),
            url: new URL(`https://1.0.0.1/`)
        })
        this.fetchData()
        return data
    }

    async debug() {
        const data = await api_mailDebug()
    }








}

