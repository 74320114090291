
import { api_addProject, api_deleteProject, api_listProject, api_renameProject } from '@/api';
// import prompt from 'antd-prompt';
import { User } from '@/user';
import { Button, Input, message, Modal, notification, Radio, Switch, Tabs } from 'antd';
// import 'antd/dist/antd.min.css';
import React, { PureComponent } from 'react';
import { Activitys } from '../Activity';
import { ViewActivitys } from '../Activity/viewer';
import "./index.less";

type viewMode = "manage" | "view" | "debug"

type ProjectProps = {
  manageURL: string | undefined
  user: User
}
type ProjectState = {
  viewMode: viewMode
  adding?: string
  items?: {
    label: string | React.ReactNode,
    children: React.ReactNode,
    key: string
  }[]
}


export class Project extends PureComponent<ProjectProps, ProjectState> {
  constructor(props: ProjectProps) {
    super(props)
    const userLevel = this.props.user.userLevel
    const defaultMode = userLevel === "viewer" ? "view" : "manage"
    const localMode = defaultMode
    // const localMode = localStorage.getItem("viewMode") as viewMode

    console.log("localMode", localMode || defaultMode)
    this.state = {
      viewMode: localMode || defaultMode
    }
  }

  render() {
    return <div>
      <Tabs
        tabBarExtraContent={this.renderSlot()}
        type={this.props.user.userLevel === "admin" ? "editable-card" : "card"}
        onEdit={this.onEdit.bind(this)}
        items={this.state.items}
      />
    </div>

  }
  renderSlot(): React.ReactNode {
    return ""
    return <Radio.Group
      onChange={(event) => {
        localStorage.setItem("viewMode", event.target.value || "view")
        document.location.reload()
      }}
      defaultValue={this.state.viewMode}
      style={{ marginRight: 8, userSelect: "none" }}>
      <Radio.Button value="debug">{"debug view"}</Radio.Button>
      <Radio.Button value="view">{"view"}</Radio.Button>
      <Radio.Button value="manage">{"manage"}</Radio.Button>
    </Radio.Group>
  }
  async componentDidMount() {
    const manageURL = new URL(this.props.manageURL || "http://undef")
    notification.open({
      message: `正在管理 ${manageURL.host}`,
      description: `当前登身份为 ${this.props.user.userName}`,
    });
    // message.success(, 1);
    this.fetchData()
  }
  async fetchData() {
    const data = await api_listProject()
    const items = Array.from(data.data, (project) => {
      return {
        label: <div onDoubleClick={() => this.onRename(project.id, project.name)}>
          {`${project.name}(ID:${project.id})`}
        </div>,
        // children: this.props.user.userLevel === "viewer" ? <ViewActivitys projectId={project.id} user={this.props.user} /> : <Activitys
        children: this.state.viewMode === "manage" ? <Activitys
          shortName={project.name}
          projectId={project.id}
          user={this.props.user}
        /> : <ViewActivitys projectId={project.id} user={this.props.user} />,
        key: String(project.id)
      }
    })
    if (items.length === 0) {
      this.onEdit("", "add")
    }
    this.setState({
      items
    })
  }
  async onEdit(event: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: "add" | "remove") {
    switch (action) {
      case "add":
        const name = prompt("新建项目的名称");
        if (name) {
          await api_addProject(name)
          await this.fetchData()
        }
        break;
      case "remove":
        const id = Number(event)
        if (confirm(`确定要删除项目 ${id} 吗?`)) {
          await api_deleteProject(id)
          await this.fetchData()
        }
        break;

      default:
        break;
    }
  }
  async onRename(projectId: number, oldName: string) {
    const newName = prompt(`把 ${oldName} 的名称改为`);
    if (newName) {
      await api_renameProject(projectId, newName)
      await this.fetchData()
    }
  }

}

