

import { api_addSchedule, api_autoHideSchedule, api_deleteSchedule, api_editSchedule, api_listSchedule, api_publishSchedule } from '@/api/children/schedule';
import { DateTimePicker } from '@/component/prompt';
import { User } from '@/user';
import {
    EditOutlined
} from '@ant-design/icons';
import { Button, Card, Drawer, Skeleton, Space, Table, Tabs, Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { PureComponent } from 'react';
import { MAIL_TEMPLATE } from './defaultMail';
import { encode, decode } from 'js-base64';
import { api_mailAdd, api_mailList } from '@/api/children/mail';
import { DotChartOutlined } from '@ant-design/icons';
import { faker } from '@faker-js/faker';

import "./index.less"
import { transformMailTemplate } from '@/function/mail/transform';


type RenderTemplateProps = {
    templateHTML: string
    to: string
    title: string
}
type State = {
    mailVariables: {
        EMAIL: string
        NAME: string
        ACTIVITY: string
    }
}

export class RenderTemplate extends PureComponent<RenderTemplateProps, State> {
    ref: React.RefObject<HTMLIFrameElement>;
    contentWindow: Window | null | undefined;
    constructor(props: RenderTemplateProps) {
        super(props)
        this.ref = React.createRef()
        const start = new Date(new Date().valueOf() + (3600 * 1000 * 24 * 16) + (3600 * 1000 * 3.6))
        const end = new Date(start.valueOf() + (3600 * 1000 * 1.2))
        const mailVariables = {
            EMAIL: faker.internet.email(),
            NAME: faker.internet.userName(),
            ACTIVITY: `示例活动名称`,
            DAY: start.toLocaleDateString(),
            WEEK: start.toLocaleDateString("zh-TW", { weekday: "long" }),
            START_TIME: start.toLocaleTimeString("zh-TW", { hour: "2-digit", minute: "2-digit", "hour12": false }),
            END_TIME: end.toLocaleTimeString("zh-TW", { hour: "2-digit", minute: "2-digit", "hour12": false }),
        }

        this.state = {
            mailVariables
        }
    }

    render() {
        const email = transformMailTemplate({
            to: this.props.to,
            title: this.props.title,
            mailHTML: this.props.templateHTML
        }, {
            ...this.state.mailVariables
        })

        // const html = email.mailHTML.replace(`<html`, `<html contenteditable="true" `)
        const html = email.mailHTML 
        const rec = `data:text/html;base64,${encode(html)}`

        return <div className='RenderTemplate' style={{ padding: 8, userSelect: "none", pointerEvents: "auto", width: "100%" }}
            onPointerMove={(event) => event.stopPropagation()}>
            <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                <Typography.Text mark>{"以下为参考样式"}</Typography.Text>
                <div>{"收件人："}{email.to}</div>
                <div>{"邮件主题："}{email.title}</div>
                <div>
                    {Array.from(email.variables, (item, id) => <Tag color={this.getTagColor(id)}>{item.value}</Tag>)}
                </div>
                <iframe
                    ref={this.ref}
                    style={{ width: "100%", minHeight: 380 }}
                    src={rec} />
            </Space>
        </div>

    }

    getTagColor(id: number) {
        const colors = ["pink", "red", "yellow", "orange", "cyan", "green", "blue", "purple", "geekblue", "magenta", "volcano", "gold", "lime"]
        const position = id % colors.length
        return colors[position]
    }




}

