import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"

export type ActivityAPIs = APIExample<{
    "/activity/pubList": {
        method: "POST"
        toServer: {
            ticket: string
            projectId: number
            //是否不进行场次可订性检测
            showMore?: boolean
        },
        toClient: {
            data: {
                id: number
                name: string
            }[]
        }
    },
    "/activity/list": {
        method: "POST"
        toServer: {
            ticket: string
            projectId: number
        },
        toClient: {
            data: {
                id: number
                published: boolean
                createTime: Date | string
                name: string
                Schedule:{}[]
            }[]
        }
    },
    "/activity/add": {
        method: "POST"
        toServer: {
            ticket: string
            projectId: number
            name: string

        },
        toClient: {}
    },
    "/activity/publish": {
        method: "POST"
        toServer: {
            ticket: string
            activityId: number
            publish: boolean
        },
        toClient: {}
    },
    "/activity/rename": {
        method: "POST"
        toServer: {
            ticket: string
            activityId: number
            newName: string
        },
        toClient: {}
    },
    "/activity/delete": {
        method: "POST"
        toServer: {
            ticket: string
            activityId: number
        },
        toClient: {}
    },

}>


export const api_listActivity = async (projectId: number) => {
    return await apiRequest("/activity/list", {
        ticket: getTicket(),
        projectId,
    })
}
export const api_addActivity = async (projectId: number, name: string) => {
    return await apiRequest("/activity/add", {
        ticket: getTicket(),
        projectId,
        "name": name
    })
}

export const api_renameActivity = async (activityId: number, newName: string) => {
    return await apiRequest("/activity/rename", {
        ticket: getTicket(),
        activityId,
        newName,
    })
}

export const api_publishActivity = async (activityId: number, publish: boolean) => {
    return await apiRequest("/activity/publish", {
        ticket: getTicket(),
        activityId,
        publish,
    })
}
export const api_deleteActivity = async (activityId: number, ) => {
    return await apiRequest("/activity/delete", {
        ticket: getTicket(),
        activityId,
    })
}