import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"

export type SeatAPIs = APIExample<{
    "/seat/pubList": {
        method: "POST"
        toServer: {
            ticket: string
            scheduleId: number
        },
        toClient: {
            data: {
                id: number;
                scheduleId: number;
                orderable: boolean;
            }[]
        }
    },
    "/seat/list": {
        method: "POST"
        toServer: {
            ticket: string
            scheduleId: number
        },
        toClient: {
            data: {
                id: number
                createTime: Date | string
                Order: {}[]
            }[]
        }
    },
    "/seat/add": {
        method: "POST"
        toServer: {
            ticket: string
            scheduleId: number
        },
        toClient: {}
    },
    "/seat/delete": {
        method: "POST"
        toServer: {
            ticket: string
            seatId: number
        },
        toClient: {}
    },

}>


export const api_listSeat = async (scheduleId: number) => {
    return await apiRequest("/seat/list", {
        ticket: getTicket(),
        scheduleId,
    })
}
export const api_addSeat = async (scheduleId: number,) => {
    return await apiRequest("/seat/add", {
        ticket: getTicket(),
        scheduleId,
    })
}
export const api_deleteSeat = async (seatId: number,) => {
    return await apiRequest("/seat/delete", {
        ticket: getTicket(),
        seatId,
    })
}